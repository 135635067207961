import { ref, computed } from 'vue'
import fetch from '@/plugin/axios'

export const cityList = ref([])

export function getCityList() {
  if (cityList.value?.length) return
  return fetch.get('/city?size=-1').then(res => {
    cityList.value = res.list
    return res
  })
}

export const articleCategoryList = ref([])

export function getArticleCategoryList() {
  if (articleCategoryList.value?.length) return
  return fetch.get('/articleCategory?size=-1').then(res => {
    articleCategoryList.value = res.list
    return res
  })
}
