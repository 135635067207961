<template lang="pug">
.page
  el-card.filterCard
    el-form(inline label-width="6em")
      el-form-item(label="地区")
        el-select(v-model="filter.city")
          el-option(label="全部" value="")
          el-option(v-for="i in cityList" :label="i.name" :value="i._id")
      el-form-item(label="搜索")
        el-input(v-model="filter.search")
      el-form-item()
        el-button(type="primary" icon="el-icon-search" @click="handleSearch") 搜索
        el-button(type="warning" icon="el-icon-refresh" @click="initData") 重置
  el-card.tableCard(v-loading="loading")
    el-table(:data="list")
      el-table-column(prop="_id" label="id")
      el-table-column(prop="name" label="名称")
      el-table-column(label="公司")
        template(#default="scope")
          el-tag(v-if="scope.row?.company") {{ scope.row?.company?.name }}
      el-table-column(label="类型")
        template(#default="scope")
          el-tag(v-if="scope.row?.dutyType") {{ scope.row?.dutyType }}
      el-table-column(label="分类")
        template(#default="scope")
          el-tag(v-if="scope.row?.jobCategory") {{ scope.row?.jobCategory?.name }}
      el-table-column(label="地区")
        template(#default="scope")
          el-tag(v-for="i in scope.row?.cityList || []" style="margin-right:5px") {{ i.name }}
      el-table-column(label="已报名")
        template(#default="scope")
          el-tag(v-if="scope.row?.applyCount" type="success" @click="handleJumpApplyList(scope.row)") {{ scope.row?.applyCount }}
          el-tag(v-else type="info") 暂无
      el-table-column(fixed="right" label="操作" width="200")
        template(#default="scope")
          el-button(type="primary" icon="el-icon-edit" size="mini" plain @click="handleEdit(scope.row)") 编辑
          el-popconfirm(title="确定要删除吗？" @confirm="handleDelete(scope.row)")
            template(#reference)
              el-button(type="danger" icon="el-icon-delete" size="mini" plain) 删除
    .footer
      el-pagination(v-model:currentPage="pagination.page"
        v-model:pageSize="pagination.size"
        :pageSizes="[10, 20, 50, 100, 200]"
        :total="pagination.total"
        layout="sizes, prev, pager, next"
        background
        @size-change="handleSearch"
        @current-change="getList")

</template>

<script setup>
import { inject, onMounted, ref } from 'vue'

const { fetch, router } = inject('global')
import { getCityList, cityList } from '@/provider/global'

const loading = ref(false)
const list = ref([])
const pagination = ref({
  page: 1,
  size: 10,
  total: 100
})
const filter = ref({
  city: '',
  search: ''
})

function handleJumpApplyList(val) {
  router.push(`/jobApply?job=${val._id}`)
}

function handleSearch() {
  list.value = []
  pagination.value.page = 1
  getList()
}
function initData() {
  filter.value.search = ''
  handleSearch()
}
function getList() {
  loading.value = true
  let params = {
    ...pagination.value
  }
  if (filter.value.search) {
    params.search = filter.value.search
  }
  if (filter.value.city) {
    params.cityList = filter.value.city
  }
  fetch
    .get('/job', { params })
    .then(res => {
      list.value = res.list
      pagination.value.total = res.page.total
    })
    .finally(() => {
      loading.value = false
    })
}

function handleEdit(val) {
  router.push(`/job/edit/${val._id}`)
}

onMounted(() => {
  getCityList()
  initData()
})
</script>

<style lang="less" scoped>
.tableCard {
  .el-table {
    min-height: 600px;
  }
}
.footer {
  text-align: center;
  padding-top: 10px;
}
</style>
